<template>
	<div>
		<div class='a-news'>
			<div class='app-container a-news-container'>
				<p class='a-heading-1'>{{$t('routes.news')}}</p>

				<div class="row a-news-search">
					<div class="col">
						<v-text-field
							:placeholder="`${$t('hc.search_news')}...`"
							icon-prepend='search'
							name='search'
							v-model='search'
							hide-details
						/>
					</div>
					<div class="col-auto">
						<div class='a-news-search-button'>
							<v-button xxsmall color='green white--text'
								@click='updateSearch'
								:loading='loading_btn'
							>{{$t('hc.search')}}</v-button>
						</div>
					</div>
				</div>

				<div class="row a-news-content">
					<div class="col-4" v-for='(item, i) in items' :key='i'>
						<div class='a-news-item'>
							<router-link :to='$i18nRoute({name: "news-single", params: {postId: item.id}})' class='a-news-item-image'>
								<img :src="item.image">
							</router-link>
							<p class='a-news-item-date'>{{$moment(item.created_at).format("DD MMMM YYYY")}}</p>
							<router-link :to='$i18nRoute({name: "news-single", params: {postId: item.id}})' class='a-news-item-title'>
								{{item.title}}
							</router-link>
							<p class='a-news-item-text'>{{item.description}}</p>
							<router-link :to='$i18nRoute({name: "news-single", params: {postId: item.id}})' class='app-link green--text a-news-item-link'>{{$t('account.buttons.read_more')}}</router-link>
						</div>
					</div>
				</div>

				<p class='a-heading-1 text-center' v-if='!loading && total === 0'>{{$t('hc.no_news')}}</p>

				<v-pagination
					:total='total > 0 ? total : 1'
					:page='page'
					:perPage='perPage'
					@update-pagination='updatePagination'
					:hideCount='true'
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import { getPosts } from '@/services/request';

	export default {
		data: () => ({
			search: '',
			items: [],
			page: 1,
			perPage: 10,
			total: 0,
			loading: false,
			loading_btn: false
		}),
		created() {
			this.getPosts();
		},
		methods: {
			getPosts() {
				this.loading = true;
				getPosts({
					search: this.search,
					page: this.page
				}).then(res => {
					this.items = res.data;
					this.page = res.meta.current_page;
					this.perPage = res.meta.per_page;
					this.total = res.meta.total;
				}).finally(() => this.loading = false, this.loading_btn = false)
			},
			updateSearch() {
				this.page = 1;
				this.loading_btn = true;
				this.getPosts();
			},
			updatePagination($event) {
				this.page = $event;
				this.getPosts();
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-news {
		padding-top: 40px;
		padding-bottom: 100px;
		@include minw( $grid-breakpoints-xs ) {
			padding-top: 70px;
		}
		&-container {
			max-width: 1155px;
		}
		&-content {
			margin-bottom: 50px;
			.col-4 {
				@media only screen and (min-width: $grid-breakpoints-xs) and (max-width: $grid-breakpoints-sm - 1) {
					flex: 0 0 calc(100% / 2);
					max-width: calc(100% / 2);
				}
			}
		}
		.a-heading-1 {
			margin-bottom: 30px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				text-align: center;
			}
		}
		&-search {
			@include minw( $grid-breakpoints-xs ) {
				align-items: center;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				flex-direction: column;
			}
			&-button {
				@include minw( $grid-breakpoints-xs ) {
					margin-bottom: 6px;
					.app-btn {
						min-width: 98px;
					}
				}
				@include maxw( $grid-breakpoints-xs - 1 ) {
					margin-top: 15px;
					.app-btn {
						width: 100%;
					}
				}
			}
		}
		&-item {
			margin-top: 50px;
			&-image {
				height: 194px;
				width: 100%;
				display: block;
				margin-bottom: 15px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			&-date {
				font-size: 14px;
				font-weight: 300;
				margin-bottom: 10px;
			}
			&-title {
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 10px;
				display: block;
			}
			&-text {
				font-size: 14px;
				font-weight: 400;
				line-height: 1.5;
				margin-bottom: 20px;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 16px;
				}
			}
			&-link {
				@include maxw( $grid-breakpoints-xs - 1 ) {
					font-size: 14px;
				}
			}
		}
	}
</style>
